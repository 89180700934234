export function getPrices() {
	return {
		'early': ['69.00', '139.00', '199.00', '279.00', '359.00'],
		'children': ['199.00', '319.00', '449.00', '599.00', '749.00'],
		'youth': ['129.00', '199.00', '299.00', '399.00', '549.00']
	};
}

export function updatePricing(event) {
	var prices = getPrices(),
		ageLevel = event.target.id,
		optionIndex = event.target.selectedIndex,
		priceElement = event.target.closest('.tile').getElementsByClassName('price')[0],
		newPrice = prices[ageLevel][optionIndex];
	
	priceElement.innerText = newPrice;
}

export function dropdown(event) {
	var content = event.target.closest('.content');
	content.classList.toggle('active');
}