import React from 'react';

const Footer = () => (
	<footer>
		<div className="wrapper">
			<div className="actions">
				<a className="cta cta--large" href="https://children.sparkhousedigital.org/Home/SignIn#signup-form">30-Day Free Trial</a>
				<a className="cta cta--large" href="https://www.wearesparkhouse.org/store/productgroup/1024/Sparkhouse-Digital">Subscribe Now</a>
			</div>

			<div className="feature feature--center">
				<p><small><em>*Student resources including but not limited to learner leaflets, posters, Bibles, journals, handbooks, and stickers are not included in a subscription to Sparkhouse Digital and still need to be purchased separately.</em></small></p>
			</div>
		</div>
	</footer>
);

export default Footer;