import React from 'react';
import { 
	BrowserRouter as Router,
	Route,
	Switch
} from 'react-router-dom';

import HomePage from '../Home';
import Maintenance from '../Maintenance';
import NotFoundPage from '../404';

import * as ROUTES from '../../constants/routes';

const App = () => {
	let maintenance = false;

	if (maintenance) {
		return (
			<Maintenance />
		);
	} else {
		return (
			<Router>
				<Switch>
					<Route exact path={ROUTES.HOME} component={HomePage} />
					<Route component={NotFoundPage} />
				</Switch>
			</Router>
		);
	}
};

export default App;