import React from 'react';
import { dropdown } from '../../utils.js';

const FAQ = () => (
	<div className="dropdown">
		<ul>
			<li className="dropdown-item">
				<div className="content">
					<h4 onClick={dropdown}>What is included in Sparkhouse Digital?</h4>

					<div className="dropdown-content">
						<p>Sparkhouse Digital offers access to key resources for your favorite Sparkhouse curricula, including:</p>
						<ul>
							<li>Leader Guides: Downloadable leader guides for each Sparkhouse curriculum makes it easy to preview lessons and easily plan upcoming lessons for your early childhood, children, and youth programs.</li>
							<li>Lesson Videos: Lesson videos are available to stream or download.</li>
							<li>Reproducible Extras: Forms, templates, signs, and coloring pages bring extra visual impact to congregations using Sparkhouse Digital and helps churches simplify parent and volunteer engagement.</li>
							<li>Exclusive Extras: Digital Activity Kits for each age level, At the Movies youth discussion guides, children&rsquo;s messages, posters, Advent and Lent extras are just some of the exclusive content you&rsquo;ll find on Sparkhouse Digital.</li>
							<li>Monthly Enewsletters: For Sparkhouse Digital Early Childhood subscribers, congregations can enroll caregivers of young children to receive monthly Enewsletters with content customized for the child&rsquo;s age and month of the year through the child&rsquo;s fifth birthday, helping parents understand key developmental and faith milestones.</li>
						</ul>
					</div>
				</div>
			</li>

			<li className="dropdown-item">
				<div className="content">
					<h4 onClick={dropdown}>What is my limit for resources that I can download?</h4>

					<div className="dropdown-content">
						<p>Sparkhouse Digital subscribers receive unlimited downloads! This gives you the flexibility you need to create a program that works for your students and fits your church&rsquo;s context.</p>
					</div>
				</div>
			</li>

			<li className="dropdown-item">
				<div className="content">
					<h4 onClick={dropdown}>Will I still need to purchase physical materials?</h4>

					<div className="dropdown-content">
						<p>Sparkhouse Digital features a hybrid curriculum model, meaning that your leader resources are available digitally and your learner resources (including learner leaflets and student handbooks, Bibles, wall charts and posters, and additional books) are print material that you purchase from Sparkhouse.</p>
					</div>
				</div>
			</li>

			<li className="dropdown-item">
				<div className="content">
					<h4 onClick={dropdown}>How much does Sparkhouse Digital cost?</h4>

					<div className="dropdown-content">
						<p>For more information on pricing for Sparkhouse Digital, please see our <a href="#pricing">pricing</a> above. Free trials are available for you to experience the digital platform for no cost for the first 30 days. Interested? <a href="https://children.sparkhousedigital.org/Home/SignIn">Sign up for your free trial today!</a></p>
					</div>
				</div>
			</li>

			<li className="dropdown-item">
				<div className="content">
					<h4 onClick={dropdown}>I don&rsquo;t have regular attendance, how am I supposed to gauge how much I need to pay if it&rsquo;s priced by student?</h4>

					<div className="dropdown-content">
						<p>We know that attendance varies throughout the year, so we recommend that you take an average to determine the appropriate pricing for your church. If you have any questions, please contact our Sales and Service team at 1-877-702-5551.</p>
					</div>
				</div>
			</li>

			<li className="dropdown-item">
				<div className="content">
					<h4 onClick={dropdown}>Can I get a free trial of Sparkhouse Digital?</h4>

					<div className="dropdown-content">
						<p>Yes! First-time users of Sparkhouse Digital can try the platform for FREE for 30 days! <a href="https://children.sparkhousedigital.org/Home/SignIn">Sign up for your free trial today!</a></p>
					</div>
				</div>
			</li>

			<li className="dropdown-item">
				<div className="content">
					<h4 onClick={dropdown}>How can I use the different curricula to create a program for my early childhood, Sunday school, or youth group?</h4>

					<div className="dropdown-content">
						<p>Customizing your program for your church&rsquo;s specific needs has never been easier! Sparkhouse Digital offers a search function so that you can easily search all of our curricula to find what you need.</p>
					</div>
				</div>
			</li>

			<li className="dropdown-item">
				<div className="content">
					<h4 onClick={dropdown}>Why would I want access to all of your curricula?</h4>

					<div className="dropdown-content">
						<p>Access to all of our curricula on one platform gives you options to create lessons that fit your program&rsquo;s needs &ndash; whether it&rsquo;s a traditional Sunday school or youth group, weekend lock-in, workshop or for summer. We know that sometimes you like to show videos, and other times, it&rsquo;s better to have interactive workshops for your kids. Sparkhouse Digital gives you that option &ndash; and makes it easy for you to find exactly what you need, when you need it.</p>
					</div>
				</div>
			</li>

			<li className="dropdown-item">
				<div className="content">
					<h4 onClick={dropdown}>Will I be able to download learner resources?</h4>

					<div className="dropdown-content">
						<p>You will need to purchase learner resources separately including learner leaflets, learner sheets, Bibles, wall charts, and posters.</p>
					</div>
				</div>
			</li>

			<li className="dropdown-item">
				<div className="content">
					<h4 onClick={dropdown}>Who can access Sparkhouse Digital at my church?</h4>

					<div className="dropdown-content">
						<p>Sparkhouse Digital allows unlimited users and was designed to give access to resources to directors and leaders.</p>
					</div>
				</div>
			</li>
		</ul>
	</div>
);

export default FAQ;