import React from 'react';
import EC from '../../images/ec.jpg';
import Children from '../../images/children.jpg';
import Youth from '../../images/youth.jpg';
import { getPrices, updatePricing } from '../../utils.js';

const Pricing = () => (
	<div className="tiles">
		<div className="tile">
			<div className="image full">
				<img src={EC} alt="group of young children in Sunday school classroom" />
			</div>
			<div className="content">
				<h3>Early Childhood</h3>
				<p>Number of kids</p>
				<form>
					<select name="slct" id="early" onChange={updatePricing}>
						<option>1-10</option>
						<option>11-20</option>
						<option>21-35</option>
						<option>36-50</option>
						<option>51+</option>
					</select>
				</form>
				<h2><span className="dollar">$</span><span className="price">{ getPrices().early[0] }</span></h2>
				<p>Annually</p>
			</div>
		</div>
		<div className="tile">
			<div className="image full">
				<img src={Children} alt="group of children looking at tablets" />
			</div>
			<div className="content">
				<h3>Children</h3>
				<p>Number of kids</p>
				<form>
					<select name="slct" id="children" onChange={updatePricing}>
						<option>1-25</option>
						<option>26-50</option>
						<option>51-75</option>
						<option>76-100</option>
						<option>101+</option>
					</select>
				</form>
				<h2><span className="dollar">$</span><span className="price">{ getPrices().children[0] }</span></h2>
				<p>Annually</p>
			</div>
		</div>
		<div className="tile">
			<div className="image full">
				<img src={Youth} alt="group of youths reading" />
			</div>
			<div className="content">
				<h3>Youth</h3>
				<p>Number of kids</p>
				<form>
					<select name="slct" id="youth" onChange={updatePricing}>
						<option>1-10</option>
						<option>11-20</option>
						<option>21-35</option>
						<option>36-50</option>
						<option>51+</option>
					</select>
				</form>
				<h2><span className="dollar">$</span><span className="price">{ getPrices().youth[0] }</span></h2>
				<p>Annually</p>
			</div>
		</div>
	</div>
);

export default Pricing;