import React from 'react';

import Navigation from '../Navigation';
import Footer from '../Footer';

import Pricing from '../Pricing';
import FAQ from '../FAQ';

import Calendar from '../../images/calendar.png';
import EarlyMonitor from '../../images/monitor-early.png';
import ChildrenMonitor from '../../images/monitor-children.png'
import YouthMonitor from '../../images/monitor-youth.png';
import Monitor from '../../images/monitor.png';
import Planner from '../../images/planner.png';

const HomePage = () => (
	<main>
		<Navigation />

		<div>
			<section className="header">
				<div className="wrapper">
					<div className="feature feature--center">
						<h1 style={{ marginTop: '0.5rem' }}>A rich and reliable resource for <span className="no-wrap">faith formation</span></h1>
						<p>Sparkhouse Digital includes unlimited access to leader resources, videos, music, reproducibles, and exclusive extras for early childhood, children, and youth.</p>
					</div>

					<div className="flex">
						<div className="tile">
							<h3>Resources</h3>
							<p>Access more than 15,000 leader resources and exclusive extras across three age categories—early childhood, children, and youth.</p>
						</div>

						<div className="tile">
							<h3>Early Childhood</h3>
							<p>Nursery, Family, and Preschool resources for birth to <span className="no-wrap">age 5.</span></p>
						</div>

						<div className="tile">
							<h3>Children</h3>
							<p>Sunday school and summer resources for children through <span className="no-wrap">grade 6.</span></p>
						</div>

						<div className="tile">
							<h3>Youth</h3>
							<p>Confirmation and youth ministry resources for middle and high school.</p>
						</div>

						<div className="tile">
							<h3>Planning</h3>
							<p>Simplify planning for easy access to lessons for leaders and volunteers.</p>
						</div>
					</div>

					<div className="actions">
						<a className="cta cta--large" href="https://children.sparkhousedigital.org/Home/SignIn#signup-form">30-Day Free Trial</a>
						<a className="cta cta--large" href="https://www.wearesparkhouse.org/store/productgroup/1024/Sparkhouse-Digital">Subscribe Now</a>
					</div>

					<img src={Monitor} alt="Sparkhouse Digital monitor" />
				</div>
			</section>

			<section id="resources">
				<div className="wrapper">
					<div className="feature feature--center">
						<h2>Access your favorite Sparkhouse resources through a digital subscription</h2>
					</div>

					<div className="feature feature--flex left">
						<div className="image">
							<img src={Calendar} alt="Sparkhouse Digital calendar" />
						</div>
						<div className="content">
							<p>Sparkhouse Digital includes unlimited access to leader resources, reproducibles, and exclusive extras for curriculum in early childhood, children, and youth. A subscription includes administrative access for directors to use the built-in planning tools to choose and assign curriculum, as well as full access for leaders to download and stream unlimited resources. <em>Student resources* and Bibles must be purchased separately.</em></p>

							<p>Newly published resources and exclusive extras are added regularly to each age level in Sparkhouse Digital. Popular Sparkhouse Digital exclusive extras include seasonal Digital Activity Kits for each age level, At the Movies youth discussion guides, children&rsquo;s messages, posters, Advent and Lent extras, and more.</p>
						</div>
					</div>
				</div>
			</section>

			<section id="early-childhood">
				<div className="wrapper">
					<div className="feature feature--center">
						<h2>Early Childhood</h2>
						<p>A subscription to Sparkhouse Digital Early Childhood includes nursery, family, and preschool resources for birth to age 5.</p>
					</div>

					<div className="feature feature--flex right">
						<div className="image">
							<img src={EarlyMonitor} alt="Sparkhouse Digital Early Childhood" />
						</div>
						<div className="content">
							<p style={{ marginBottom: "0", fontWeight: 'bold' }}>Curriculum included:</p>
							<ul>
								<li>Frolic Nursery</li>
								<li>Frolic Family</li>
								<li>Frolic Preschool</li>
								<li>Spark: Activate Faith (Age 2 to PreK)</li>
								<li>Whirl (PreK)</li>
							</ul>

							<p style={{ marginBottom: "0", fontWeight: 'bold' }}>Popular exclusive extras:</p>
							<ul>
								<li>Digital Activity Kits</li>
								<li>Printable posters</li>
								<li>Children&rsquo;s messages</li>
								<li>Christmas program</li>
								<li>Frolic character puppets</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section id="children">
				<div className="wrapper">
					<div className="feature feature--center">
						<h2>Children</h2>
						<p>A subscription to Sparkhouse Digital Children includes Sunday school and summer resources for children through grade 6.</p>
					</div>

					<div className="feature feature--flex right">
						<div className="image">
							<img src={ChildrenMonitor} alt="Sparkhouse Digital Children" />
						</div>
						<div className="content">
							<p style={{ marginBottom: "0", fontWeight: 'bold' }}>Curriculum included:</p>
							<ul>
								<li>Spark: Activate Faith</li>
								<li>Whirl</li>
								<li>Holy Moly</li>
								<li>Connect</li>
								<li>Sparkhouse in the Summer</li>
							</ul>

							<p style={{ marginBottom: "0", fontWeight: 'bold' }}>Popular exclusive extras:</p>
							<ul>
								<li>Digital Activity Kits</li>
								<li>Faith Together at Home</li>
								<li>Children&rsquo;s messages</li>
								<li>Christmas program</li>
								<li>Bonus lessons</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section id="youth">
				<div className="wrapper">
					<div className="feature feature--center">
						<h2>Youth</h2>
						<p>A subscription to Sparkhouse Digital Youth includes confirmation and youth ministry resources for middle and high school.</p>
					</div>

					<div className="feature feature--flex right">
						<div className="image">
							<img src={YouthMonitor} alt="Sparkhouse Digital Youth" />
						</div>
						<div className="content">
							<p style={{ marginBottom: "0", fontWeight: 'bold' }}>Curriculum included:</p>
							<ul>
								<li>Colaborate</li>
								<li>Connect</li>
								<li>Echo the Story</li>
								<li>Here We Stand</li>
								<li>Re:form</li>
								<li>T.B.D.: Think. Believe. Do.</li>
								<li>Wholeness &amp; Holiness</li>
							</ul>

							<p style={{ marginBottom: "0", fontWeight: 'bold' }}>Popular exclusive extras:</p>
							<ul>
								<li>At the Movies</li>
								<li>Digital Activity Kits</li>
								<li>On the Drive In podcasts</li>
								<li>Beyond Worship Notes</li>
								<li>Living Word Christmas Program</li>
								<li>Lenten Study</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section id="planning">
				<div className="wrapper">
					<div className="feature feature--center">
						<h2>Leverage the unique planning tools</h2>
						<p>Quickly add a lesson or resource to a specific date or use the planning features to plan your entire season or year.</p>
					</div>

					<img src={Planner} alt="Sparkhouse Digital planner" />
				</div>
			</section>

			<section id="faq">
				<div className="wrapper">
					<div className="feature feature--center">
						<h2>Frequently Asked Questions</h2>
					</div>

					<FAQ />
				</div>
			</section>

			<section id="pricing">
				<div className="wrapper">
					<div className="feature feature--center">
						<h2>Pricing</h2>
					</div>

					<Pricing />

					<p style={{ textAlign: 'center' }}><small>All pricing is shown in US Dollars. Please keep exchange rates in mind when placing your order.</small></p>
				</div>
			</section>
		</div>

		<Footer />
	</main>
);

export default HomePage;