import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import * as ROUTES from '../../constants/routes';

const Navigation = () => (
	<header>
		<div className="logo">
			<Link to={ROUTES.HOME}>Home</Link>
		</div>

		<ul>
			<li>
				<Link to={ROUTES.RESOURCES}>Resources</Link>
			</li>
			<li>
				<Link to={ROUTES.EC}>Early Childhood</Link>
			</li>
			<li>
				<Link to={ROUTES.CHILDREN}>Children</Link>
			</li>
			<li>
				<Link to={ROUTES.YOUTH}>Youth</Link>
			</li>
			<li>
				<Link to={ROUTES.PLANNING}>Planning</Link>
			</li>
			<li>
				<Link to={ROUTES.FAQ}>FAQ</Link>
			</li>
			<li>
				<Link to={ROUTES.PRICING}>Pricing</Link>
			</li>
			<li className="sign-in">
				<a className="cta" href="https://children.sparkhousedigital.org/Home/SignIn">Sign In</a>
			</li>
		</ul>
	</header>
);

export default Navigation;